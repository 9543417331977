import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

const testimonials = document.querySelectorAll('.testimonial-slider .testimonial-container')

document.addEventListener("DOMContentLoaded", function () {
    testimonials.forEach((obj, index) => {
        var testimonial = new Swiper(obj, {
            spaceBetween: 0,
            slidesPerView: 1,
            loop: true,
            loopedSlides: 1, //looped slides should be the same
            pagination: {
                el: '.testimonial-slider-' + index + ' .slider-pagination',
                type: 'fraction',
            },
        
            navigation: {
                nextEl: '.testimonial-slider-' + index + ' .next',
                prevEl: '.testimonial-slider-' + index + ' .prev',
            },
            breakpoints: {
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                  loopedSlides: 2, //looped slides should be the same
                }
              }
        })
    }) 
})


